import Dates from '../../shared/helpers/Dates';
import { UNCATEGORIZED } from '../constants';
import Item from './Item';

export default {
  /**
   * @param {{id: string, attributes: { cell_phone: string, status: number, timezone: string } }} resource
   *
   * @return {{id: string, timezone: string}}
   */
  formatAttendee({
    id,
    attributes: { cell_phone: cellPhone, status, timezone },
  }) {
    return { id, cellPhone, status, timezone };
  },

  /**
   * @param {string} resource
   * @param {string|null} timezone
   *
   * @returns {Spacetime}
   */
  formatDate(resource, timezone = null) {
    return Dates.parse(resource, timezone);
  },

  /**
   * @param {array} data
   * @param {array} included
   *
   * @returns {array}
   */
  formatForm(data, included) {
    let questions = Item.included(included, 'questions', []);

    questions = Item.sort(
      questions.map((question) => {
        const optionIds = Item.resolve(
          question,
          'relationships.options.data',
          [],
        ).map((option) => option.id);
        const options = Item.length(optionIds)
          ? Item.included(included, 'options', []).filter((option) =>
              optionIds.includes(option.id),
            )
          : [];

        return {
          id: question.id,
          ...question.attributes,
          options: Item.sort(
            options.map((option) => ({
              id: option.id,
              ...option.attributes,
              text: option.attributes.value,
              value: Number(option.id),
            })),
            'order',
          ),
        };
      }),
      'order',
    );

    return questions;
  },

  /**
   * @param {{id: string, attributes: object}} resource
   *
   * @returns Object
   */
  formatLocation(resource) {
    const { id, attributes } = resource;

    return {
      id,
      external_id: attributes.external_id,
      coordinates: {
        lat: attributes.latitude,
        lng: attributes.longitude,
      },
      country: attributes.country,
      formattedAddress: attributes.formatted_address,
      hours: attributes.hours,
      lang: attributes.lang,
      mailingCode: attributes.mailing_code,
      name: attributes.name ? attributes.name : attributes.address,
      phone: attributes.phone,
      physical: attributes.physical,
      region: attributes.region,
      reviewLink: attributes.review_link,
      sortOrder: attributes.sort_order,
      timezone: attributes.timezone,
      visibility: attributes.visibility,
      waitTime: {},
      within: true,

      /**
       * @returns {boolean}
       */
      open() {
        const hours = this.hours[Dates.dayName()];

        if (!hours.open || !hours.closed) {
          return false;
        }

        if (Dates.time(hours.closed).isBefore(Dates.time(hours.open))) {
          throw new Error('Closed hours cannot be before open');
        }

        const now = Dates.now();
        const open = Dates.time(hours.open);
        const closed = Dates.time(hours.closed);

        if (now.isBefore(open) || now.isAfter(closed)) {
          return false;
        }

        return true;
      },
    };
  },

  /**
   * @param {Object} resource
   *
   * @returns Object
   */
  formatV3Location(resource) {
    return {
      ...resource,
      coordinates: {
        lat: resource.latitude,
        lng: resource.longitude,
      },
      formattedAddress: resource.formatted_address,
      hours: {
        sunday: {
          open: resource.sunday_open,
          closed: resource.sunday_closed,
        },
        monday: {
          open: resource.monday_open,
          closed: resource.monday_closed,
        },
        tuesday: {
          open: resource.tuesday_open,
          closed: resource.tuesday_closed,
        },
        wednesday: {
          open: resource.wednesday_open,
          closed: resource.wednesday_closed,
        },
        thursday: {
          open: resource.thursday_open,
          closed: resource.thursday_closed,
        },
        friday: {
          open: resource.friday_open,
          closed: resource.friday_closed,
        },
        saturday: {
          open: resource.saturday_open,
          closed: resource.saturday_closed,
        },
      },
      waitTime: {},
      within: true,
    };
  },

  /**
   * Get unformatted resource from location object
   *
   * @param {Object} location
   *
   * @returns Object
   */
  unformatLocation(location) {
    return {
      id: location.id,
      attributes: {
        lang: location.lang,
        latitude: location.latitude,
        longitude: location.longitude,
        formatted_address: location.formattedAddress,
        hours: location.hours,
        mailing_code: location.mailingCode,
        name: location.name,
        phone: location.phone,
        physical: location.physical,
        sort_order: location.sortOrder,
        timezone: location.timezone,
        visibility: location.visibility,
      },
    };
  },

  /**
   * @param {{id: string, attributes: object, relationships: (array|undefined)}} resource
   *
   * @returns Object
   */
  formatService(resource) {
    const { id, attributes } = resource;

    return {
      id,
      name: attributes.name,
      description: attributes.description,
      instructions: attributes.instructions,
      duration: attributes.duration,
      externalId: attributes.external_id,
      group: attributes.group,
      maxDuration: attributes.max_duration,
      meetingMethods: attributes.meeting_methods,
      minDuration: attributes.min_duration,
      requireLocation: attributes.require_location_selection,
      categories:
        Item.has(resource, 'relationships') &&
        Item.has(resource.relationships, 'categories') &&
        Item.has(resource.relationships.categories, 'data')
          ? resource.relationships.categories.data.map(
              (category) => category.id,
            )
          : [UNCATEGORIZED],
      visibility: attributes.visibility,
      timeToCancel: attributes.time_to_cancel,
      timeToReschedule: attributes.time_to_reschedule,
      bookAnotherAppointmentPrefillSetting: attributes.rebook_link_setting,
    };
  },

  /**
   * @param {Object} resource
   *
   * @returns {Object}
   */
  formatV3Service(resource) {
    return {
      id: resource.id,
      name: resource.name,
      description: resource.description,
      instructions: resource.instructions,
      duration: resource.duration,
      group: Boolean(resource.group),
      maxDuration: resource.max_duration,
      meetingMethods: resource.meeting_methods,
      minDuration: resource.min_duration,
      requireLocation: resource.require_location_selection,
      categories: resource.categories.map((category) => category.id),
      visibility: resource.visibility,
      fastRouteMessage: resource.fast_route_message,
      fastRouteURL: resource.fast_route_url,
      timeToCancel: resource.time_to_cancel,
      timeToReschedule: resource.time_to_reschedule,
      bookAnotherAppointmentPrefillSetting: resource.rebook_link_setting,
      externalId: resource.external_id,
    };
  },

  /**
   * @param {{id: string, attributes: (object|undefined)}} resource
   *
   * @returns Object
   */
  formatUser(resource) {
    const { id, attributes } = resource;

    const user = {
      id,
    };

    if (attributes) {
      user.jobTitle = attributes.job_title;
      user.profilePhotoUrl = attributes.profile_photo_url;
      user.meeting_info = attributes.meeting_info;
      user.meeting_link = attributes.meeting_link;
      user.meetingMethods = attributes.meeting_methods;
      user.supportedLocales = attributes.supported_locales;
      user.spokenLanguages = attributes.spoken_languages;
      user.name = `${attributes.first_name} ${attributes.last_name}`;
      user.visibility = attributes.visibility;
    }

    return user;
  },

  /**
   * @param {{id: string, attributes: object, relationships: (array|undefined)}} resource
   *
   * @returns Object
   */
  formatCategory(resource) {
    const { id, attributes } = resource;

    return {
      id,
      name: attributes.name,
      sort_order: attributes.sort_order,
    };
  },
};
