import React, { createContext, useContext } from 'react';
import type { FC, ReactNode } from 'react';
import { useVendor } from '../../backend/contexts/VendorContext';

type Features = Record<string, boolean | undefined>;

const FeatureDecisionContext = createContext<Features>({});

const useFeatureDecisions = () => useContext(FeatureDecisionContext);

interface FeatureDecisionProviderProps {
  children: ReactNode;
}

const FeatureDecisionProvider: FC<FeatureDecisionProviderProps> = ({
  children,
}) => {
  const { vendor } = useVendor();

  // in /backend, the features come from redux in the vendor object.
  // in /frontend, they come off window
  const features: Features =
    (vendor as any)?.featureDecisions ??
    (window as any).state?.featureDecisions ??
    {};

  return (
    <FeatureDecisionContext.Provider value={features}>
      {children}
    </FeatureDecisionContext.Provider>
  );
};

export { FeatureDecisionContext, FeatureDecisionProvider, useFeatureDecisions };
