import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { connect } from 'react-redux';
import {
  updateBranchLocatorSettings,
  updateAdminViewSettings,
  updateClientViewSettings,
  updateSupportSettings,
  updateNotificationSettings,
  updateVideoIntegrationSettings,
  addReminder,
  deleteReminder,
  updateReminder,
  updateContactCentreSettings,
} from '../actions/vendor';
import vendorShape from '../shapes/VendorShape';

// TODO remove redux mapping once all areas no longer use redux for vendor
const mapStateToProps = (state) => ({
  vendor: state.vendor,
});
const mapDispatchToProps = {
  updateBranchLocatorSettings,
  updateClientViewSettings,
  updateAdminViewSettings,
  updateSupportSettings,
  updateNotificationSettings,
  updateVideoIntegrationSettings,
  addReminder,
  deleteReminder,
  updateReminder,
  updateContactCentreSettings,
};

/**
 * @type {React.Context<{ vendor: any | null }>}
 */
const VendorContext = createContext({ vendor: null });

const useVendor = () => useContext(VendorContext);

// TODO: create state to hold vendor and update functions
const VendorProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ({
    children,
    updateBranchLocatorSettings,
    updateClientViewSettings,
    updateAdminViewSettings,
    updateSupportSettings,
    updateNotificationSettings,
    updateVideoIntegrationSettings,
    addReminder,
    deleteReminder,
    updateReminder,
    updateContactCentreSettings,
    vendor,
  }) => {
    // TODO use this to hold vendor once redux is removed
    // const [vendor, setVendor] = useState(null);

    // const updateBranchLocatorSettings = (updates) => {
    //   setVendor({
    //     ...vendor,
    //     branch_locator: {
    //       ...vendor.branch_locator,
    //       ...updates,
    //     },
    //   });
    // };

    // const updateClientViewSettings = (updates) => {
    //   setVendor({
    //     ...vendor,
    //     client_view: {
    //       ...vendor.client_view,
    //       ...updates,
    //     },
    //   });
    // };

    // const updateAdminViewSettings = (updates) => {
    //   setVendor({
    //     ...vendor,
    //     admin_view: {
    //       ...vendor.admin_view,
    //       ...updates,
    //     },
    //   });
    // };

    // const updateSupportSettings = (updates) => {
    //   setVendor({
    //     ...vendor,
    //     ...updates,
    //   });
    // };

    // const updateNotificationSettings = (updates) => {
    //   setVendor({
    //     ...vendor,
    //     notifications: {
    //       ...vendor.notifications,
    //       ...updates,
    //     },
    //   });
    // };

    // const updateVideoIntegrationSettings = (updates) => {
    //   setVendor({
    //     ...vendor,
    //     video_integration: {
    //       ...vendor.video_integration,
    //       ...updates,
    //     },
    //   });
    // };

    // const addReminder = (updates) => {
    //   setVendor({
    //     ...vendor,
    //     notifications: {
    //       ...vendor.notifications,
    //       reminders_client: [...vendor.notifications.reminders_client, { ...updates }],
    //     },
    //   });
    // };

    // const deleteReminder = (id) => {
    //   setVendor({
    //     ...vendor,
    //     notifications: {
    //       ...vendor.notifications,
    //       reminders_staff: vendor.notifications.reminders_staff
    //         .filter(obj => obj.id !== id),
    //     },
    //   });
    // };

    // const updateReminder = (id, updates) => {
    //   setVendor({
    //     ...vendor,
    //     notifications: {
    //       ...vendor.notifications,
    //       reminders_staff: vendor.notifications.reminders_staff
    //         .map(obj => (obj.id === id ? { ...updates } : obj)),
    //     },
    //   });
    // };

    // const updateContactCentreSettings = (updates) => {
    //   setVendor({
    //     ...vendor,
    //     contact_centre: {
    //       ...vendor.contact_centre,
    //       ...updates,
    //     },
    //   });
    // };

    return (
      <VendorContext.Provider
        value={{
          vendor,
          updateBranchLocatorSettings,
          updateAdminViewSettings,
          updateClientViewSettings,
          updateSupportSettings,
          updateNotificationSettings,
          updateVideoIntegrationSettings,
          addReminder,
          deleteReminder,
          updateReminder,
          updateContactCentreSettings,
        }}
      >
        {children}
      </VendorContext.Provider>
    );
  },
);

VendorProvider.propTypes = {
  children: PropTypes.element.isRequired,
  vendor: vendorShape,
};

export { VendorContext, VendorProvider, useVendor };
