import PropTypes from 'prop-types';
import { CLIENT_VIEW_LAYOUTS } from '../constants';

export const testVendor = {
  client_view: {
    enabled: true,
    language: 'en',
    layout: CLIENT_VIEW_LAYOUTS.MODERN,
  },
  admin_view: {
    primary_phone_type: 'cell_phone',
    admin_time_in_advance: null,
  },
  currency: '$',
  features: [],
  id: 1,
  name: 'Test Vendor',
  notifications: {},
  schedule_interval: 30,
  service_interval: 15,
  social: false,
};

export default PropTypes.shape({
  client_view: PropTypes.shape({
    enabled: PropTypes.bool,
    language: PropTypes.string,
    layout: PropTypes.oneOf([
      CLIENT_VIEW_LAYOUTS.CLASSIC,
      CLIENT_VIEW_LAYOUTS.MODERN,
    ]),
  }).isRequired,
  currency: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  schedule_interval: PropTypes.number.isRequired,
  service_interval: PropTypes.number.isRequired,
  social: PropTypes.bool.isRequired,
});
