import { Alert, TextInput } from '@coconut-software/ui';
import ct from 'countries-and-timezones';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Validator from 'validatorjs';
import mode from '../../../shared/helpers/Mode';
import Url from '../../../shared/helpers/Url';
import UTM from '../../../shared/helpers/UtmParams';
import Button from '../../components/Button';
import RadioButton from '../../components/forms/RadioButton';
import RadioButtonGroup from '../../components/forms/RadioButtonGroup';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import Typography from '../../components/Typography';
import { MEETING_METHODS, REQUIRED_VALUES } from '../../constants';
import { FeatureContext } from '../../contexts/FeatureContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { TimezoneContext } from '../../contexts/TimezoneContext';
import Api from '../../helpers/Api';

const combineState = (state, newState) => ({ ...state, ...newState });

const defaultValues = {
  contact: '',
  fetchFailed: false,
  notFound: false,
  touched: false,
  type: 'email',
};

const RULES = {
  phone: 'required',
  email: 'required|max:255|email',
};

const LandingPage = ({ setAppointment, setContact }) => {
  const intl = useIntl();
  const timezone = useContext(TimezoneContext);
  // eslint-disable-next-line react/destructuring-assignment
  const country = ct.getCountryForTimezone(timezone[0])?.id;
  const history = useHistory();
  const [locale] = useContext(LocaleContext);
  const features = React.useContext(FeatureContext);
  const { clientFields } = useContext(SettingsContext);
  const showPhone =
    clientFields &&
    (clientFields.phone !== REQUIRED_VALUES.NA ||
      clientFields.mobile_phone !== REQUIRED_VALUES.NA ||
      clientFields.work_phone !== REQUIRED_VALUES.NA);

  const [errors, setErrors] = useReducer(combineState, {});
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [queueable, setQueueable] = useState(false);
  const [preference, setPreference] = useState('email');
  const [
    {
      location: { id: locationId },
    },
  ] = useContext(SelectionContext);

  const MESSAGES = useMemo(
    () => ({
      required: intl.formatMessage({ id: 'Form.field_required' }),
      max: intl.formatMessage(
        { id: 'Form.field_error_max_length' },
        { max: ':max' },
      ),
      email: intl.formatMessage({ id: 'Form.field_error.email_structure' }),
    }),
    [intl],
  );

  useEffect(() => {
    if (features.lobby) {
      Api.locations()
        .queueable(locationId)
        .then((res) => {
          setQueueable(res.queueable);
        });
    }
  }, [locationId, features.lobby]);

  useEffect(() => {
    setValues(defaultValues);
    setErrors({
      [preference]: false,
      notFound: false,
      fetchFailed: false,
      phone: false,
      email: false,
    });
  }, [preference]);

  const validateField = useCallback(
    (field, value, valid = true) => {
      if (field === 'phone' && value && !valid) {
        setErrors({
          [field]: intl.formatMessage({
            id: 'Form.field_error.cell_phone_characters',
          }),
        });

        return false;
      }

      const validator = new Validator(
        { [field]: value },
        { [field]: RULES[field] },
        MESSAGES,
      );

      if (validator.fails()) {
        setErrors({ [field]: validator.errors.first(field) });
      } else {
        setErrors({ [field]: false });
      }

      return validator.errors.get(field).length === 0;
    },
    [MESSAGES, intl],
  );

  const updateContact = (name, value) => {
    setValues({
      contact: value,
      touched: true,
      type: name,
    });
  };

  const joinLine = () => {
    const searchParams = new URLSearchParams({
      meeting_method: MEETING_METHODS.AT_LOCATION,
      lang: locale,
      ...UTM.getUtmParamsObject(Url.params(history.location.search)),
    });

    if (mode.isKiosk()) {
      searchParams.set('mode', 'kiosk');
    }

    window.location.assign(
      `/callback-service/${locationId}?${searchParams.toString()}`,
    );
  };

  const getAlertMessage = useMemo(() => {
    let errorMessage;
    if (errors.fetchFailed) {
      errorMessage = intl.formatMessage({ id: 'CheckIn.fetch_failed' });
    } else if (errors.notFound && preference === 'email') {
      errorMessage = intl.formatMessage({ id: 'CheckIn.not_found.email' });
    } else if (errors.notFound && preference === 'phone') {
      errorMessage = intl.formatMessage({ id: 'CheckIn.not_found.phone' });
    }

    return errorMessage;
  }, [intl, errors.fetchFailed, errors.notFound, preference]);

  const handleSubmit = () => {
    if (
      (preference === 'phone' && !validateField('phone', values.contact)) ||
      (preference === 'email' && !validateField('email', values.contact))
    ) {
      return;
    }

    setErrors({ notFound: false, fetchFailed: false });
    setLoading(true);

    Api.checkIn()
      .find({
        location: locationId,
        preference,
        contact: values.contact,
      })
      .then((data) => {
        setAppointment(data);
        setContact({ type: values.type, value: values.contact });
      })
      .catch((err) => {
        if (err.code === 404) {
          setErrors({
            notFound: true,
          });
        } else {
          setErrors({
            fetchFailed: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Typography component="h2" variant="h5">
        <FormattedMessage id="CheckIn.landing_page.title" />
      </Typography>
      <div className={`mt-3 ${showPhone ? 'mb-6' : ''}`}>
        <Typography component="p" variant="subtitle">
          <FormattedMessage
            id={showPhone ? 'CheckIn.subtitle' : 'CheckIn.subtitle_email_only'}
          />
        </Typography>
      </div>
      <RadioButtonGroup
        aria-label={intl.formatMessage(
          { id: 'CheckIn.preference' },
          { preference },
        )}
        name="preference"
        onChange={setPreference}
        value={preference}
      >
        <div className="flex w-full flex-nowrap mb-4">
          {showPhone ? (
            <>
              <RadioButton
                ariaLabel={intl.formatMessage({ id: 'CheckIn.email' })}
                id="email-preference"
                value="email"
              >
                <svg
                  aria-hidden="true"
                  fill="currentColor"
                  height="24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                </svg>
                <Typography aria-hidden="true">
                  <FormattedMessage id="CheckIn.email" />
                </Typography>
              </RadioButton>
              <RadioButton
                ariaLabel={intl.formatMessage({ id: 'CheckIn.phone' })}
                id="phone-preference"
                value="phone"
              >
                <svg
                  aria-hidden="true"
                  fill="currentColor"
                  height="24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56a.977.977 0 00-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
                </svg>
                <Typography aria-hidden="true">
                  <FormattedMessage id="CheckIn.phone" />
                </Typography>
              </RadioButton>
            </>
          ) : null}
        </div>
      </RadioButtonGroup>
      {preference === 'email' ? (
        <TextInput
          error={!!errors.email}
          helperText={errors.email ? errors.email : ''}
          label={intl.formatMessage({ id: 'CheckIn.email' })}
          name="email"
          onBlur={(event) => {
            validateField(event.currentTarget.name, event.currentTarget.value);
            updateContact('email', event.target.value);
          }}
          size="medium"
          variant="outlined"
        />
      ) : null}
      {preference === 'phone' ? (
        <PhoneNumberInput
          country={country}
          error={!!errors.phone}
          helperText={errors.phone ? errors.phone : ''}
          onPhoneChange={(phone) => {
            validateField('phone', phone.number, phone.valid);
            updateContact('phone', phone.number);
          }}
          onValidityChange={(valid) => {
            if (errors.phone) {
              validateField('phone', 'x', valid);
            }
          }}
        />
      ) : null}
      {errors.notFound || errors.fetchFailed ? (
        <div className="mt-8 w-full">
          <Alert severity="error" variant="minimal">
            <Alert.Content>
              <Alert.Description>
                <Typography component="p" variant="subtitle">
                  {getAlertMessage}
                </Typography>
              </Alert.Description>
            </Alert.Content>
          </Alert>
        </div>
      ) : null}
      <div className="my-8">
        <Button
          aria-label={intl.formatMessage({ id: 'CheckIn.next.aria_label' })}
          disabled={errors.phone || errors.email || loading}
          isLoading={loading}
          onClick={handleSubmit}
          type="submit"
        >
          <FormattedMessage id="CheckIn.next" />
        </Button>
      </div>
      {queueable ? (
        <>
          <hr className="border-0 border-t border-gray-400" />
          <div className="mt-2">
            <div className="m-4 text-center">
              <Typography component="h3" variant="subtitle">
                <FormattedMessage id="CheckIn.join_line.title" />
              </Typography>
            </div>
            <Button
              aria-label={intl.formatMessage({
                id: 'CheckIn.join_line.aria_label',
              })}
              onClick={joinLine}
              variant="outlined"
            >
              <FormattedMessage id="CheckIn.join_line" />
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
};

LandingPage.propTypes = {
  setAppointment: PropTypes.func.isRequired,
  setContact: PropTypes.func.isRequired,
};

export default LandingPage;
